$(document).ready(function () {


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teleport links
    // *
    // * @set outer parent element class: js-href-teleport-wrapper
    // * @set link (<a> tag) element class: js-href-teleport-link
    // * @set element to add the link to class: js-href-teleport
    // *
    // * This adds a link tag (<a>) to other elements within a wrapper
    // * links comes from a link. Example: add a link to h2, image etc. inside a teaser
    // *
    $(".js-href-teleport").each(function () {
        var $link = $(this).parents(".js-href-teleport-wrapper").find(".js-href-teleport-link"),
            href = $link.attr("href"),
            target = $link.attr("target") ? $link.attr("target") : '_self';

        if (href) {
            $(this).wrapInner('<a href="' + href + '" target="' + target + '"></a>');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * parent clickable elements (excludes links inside)
    // *
    // * @set outer parent element class: js-click-item-parent
    // * @set link (<a> tag) element class: js-click-item-link
    // *
    // * This makes the whole element clickable and still
    // * makes other links inside clickable with their target
    // *
    $(".js-click-item-parent").delegate('a', 'click', function (e) {
        var target = $(this).attr("target"),
            url = $(this).attr("href");

        if (target == "_blank") {
            window.open(url);
        } else {
            window.location = url;
        }
        return false;
    }).click(function () {
        var target = $(this).find("a.js-click-item-link").attr("target"),
            url = $(this).find("a.js-click-item-link").attr("href");

        if (target == "_blank") {
            window.open(url);
        } else {
            window.location = url;
        }
        return false;
    });




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * animateIn
    // *
    // *
    var offset = 80; // Distance from Browserbottom & -top where the animation should start

    function fadeInElements() {
        var viewPortStart = $(window).scrollTop(),
            viewPortEnd = viewPortStart + $(window).height();


        $(".animateIn:visible").each(function () {
            var elementTop = $(this).offset().top,
                elementBottom = $(this).offset().top + $(this).outerHeight();

            if ((elementTop + offset) <= viewPortEnd && (elementBottom - offset) >= viewPortStart) {
                var delay = $(this).data("animation-delay"),
                    desktopDelay = $(this).data("animation-desktop-delay");
                $(this).css("transition-delay", delay + "s").addClass("animateIn--active");

                if ($(window).width() > 1000) {
                    $(this).css("transition-delay", desktopDelay + "s").addClass("animateIn--active");
                }
            } else if ($(this).hasClass("bidirect")) {
                $(this).removeClass("animateIn--active");
            }
        });
    }

    $(window).scroll(function () {
        fadeInElements();
    });

    fadeInElements();


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add target blank to external links
    // *
    // *
    $('a:not([data-targetblank=ignore])').each(function () {
        if (location.hostname === this.hostname || !this.hostname.length) {
            // ... do nothing?
        } else {
            $(this).attr('target', '_blank');
        }
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * videomega
    // *
    // *
    $(".js-videomega").click(function () {
        var $video = $(this).find("video");
        $video.prop("muted", !$video.prop("muted"));
        $(this).find(".js-videomega-soundbutton").toggleClass("active");
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * sliderTeaser
    // *
    // *
    const sliderTeaser = new Swiper('.js-slider-teaser', {
        loop: true,
        parallax: true,
        speed: 1000,
        // Navigation arrows
        navigation: {
            nextEl: '.swiper-button-next',
        },

    });

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * sliderAuto
    // *
    // *
    const swiperAuto = new Swiper('.js-slider-auto', {
        loop: true,
        slidesPerView: 1,
        spaceBetween: 25,
        allowTouchMove: true,
        speed: 2500,
        touchEventsTarget: 'container',
        autoplay: {
            enabled: true,
            delay: 500,
            disableOnInteraction: true
        },


        breakpoints: {
            // when window width is >= XXX
            1600: {
                slidesPerView: 5,
                allowTouchMove: true,
                spaceBetween: 50,
                autoplay: {
                    enabled: true,
                    pauseOnMouseEnter: true,
                    delay: 500,
                    // delay: 0,
                },
            },
            1400: {
                slidesPerView: 5,
                spaceBetween: 38,
                autoplay: {
                    enabled: true,
                    pauseOnMouseEnter: true,
                    delay: 500,
                    // delay: 0,
                },

            },
            800: {
                slidesPerView: 3,
                spaceBetween: 38,
                autoplay: {
                    enabled: true,
                    pauseOnMouseEnter: true,
                    delay: 500,
                    // delay: 0,
                },

            },
            0: {
                slidesPerView: 1.5,
                allowTouchMove: true,
                spaceBetween: 25,
                autoplay: {
                    enabled: true,
                    delay: 500,
                    pauseOnMouseEnter: true,
                    // delay: 0,
                }
            }
        }
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * sliderHeadline
    // *
    // *
    const sliderHeadline = new Swiper('.js-slider-headline', {
        loop: true,
        parallax: true,
        speed: 1000,
        spaceBetween: 200,
        // Navigation arrows
        navigation: {
            nextEl: '.swiper-button-next',
        },

    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * sliderMgea
    // *
    // *
    const sliderMega = new Swiper('.js-slider-mega', {
        loop: true,
        speed: 1000,
        spaceBetween: 50,
        // Navigation arrows
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * image features
    // *
    // *
    $(".js-image-feature-dot").click(function () {
        $(this).siblings(".js-image-feature-dot").toggle();
        $(this).toggleClass("active");
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * navButton
    // *
    // *
    $(".js-navbutton").click(function () {
        $(this).toggleClass("active");
        $("body").toggleClass("freeze");
        $(".js-navmobile").toggleClass("active");
        $(".js-header").toggleClass("navOpen");
    });




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // *  header slideUp
    // *
    // *
    var lastScrollTop = 0;

    $(window).scroll(function () {
        var newScrollTop = $(this).scrollTop();

        if (newScrollTop > lastScrollTop) {
            // if(newScrollTop >= 100) {
            $(".js-header").addClass("slideUp");
            // }
        } else {
            // if(newScrollTop < 100) {
            $(".js-header").removeClass("slideUp");
            // }
        }

        lastScrollTop = newScrollTop;
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * GSAP Globals
    // *
    // *
    // smooth scrolling (esp. for safari)
    if ($(window).width() > 700) {
        if ($(".js-smooth-content").length) {
            ScrollSmoother.create({
                content: $(".js-smooth-content"),
                smooth: 1
            });
        }
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * stageFixed
    // *
    // *
    var $stageFixed = $(".js-stagefixed");

    if ($stageFixed.length) {
        $stageFixed.each(function () {
            var $this = $(this);

            gsap.matchMedia().add("(min-width: 701px)", () => {

                ScrollTrigger.create({
                    trigger: $this,
                    scrub: 0.5,
                    pin: true,
                    // markers: true,
                    pinSpacing: false,
                    start: "top top"
                })

            });
        });
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * moveHEadline
    // *
    // *
    var $moveHeadline = $(".js-moveheadline");

    if ($moveHeadline.length) {
        $moveHeadline.each(function () {
            var $this = $(this),
                headline = $(this).find(".js-moveheadline-headline");

            var moveHeadlineTimeline = gsap.timeline({
                scrollTrigger: {
                    trigger: $this,
                    scrub: 0.5,
                    // pin: true,
                    // markers: true,
                    start: "top-=100 center",
                    end: "bottom-=300 center"
                }
            });

            moveHeadlineTimeline.fromTo(headline, { x: "-100%", opacity: 1 }, { x: 0 })

        });
    }

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * scrolldown
    // *
    // *
    $(".js-scrolldown").click(function () {
        var offset = $(this).next().offset().top;
        window.scrollTo(0, offset);
    })



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * productSelect
    // *
    // *
    if ($(".js-product-select").length) {
        $(".js-product-select").on('change', function () {
            var selectedPrice = $(this).find(':selected').data('price');
            $(this).parents(".js-product-form").find(".js-product-price").html(selectedPrice);
        })

        $(".js-product-select").trigger("change");
    }

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * filter
    // *
    // *
    $(".js-car-filter").on("change", function () {
        var filter = $(this).val();
        $(".js-car-filter-item").hide();
        $(".js-car-filter-item-" + filter).show();
        // $(".js-car-filter").prop('selectedIndex',0);
        $(this).parents("li").siblings().find(".js-car-filter").prop('selectedIndex', 0);
    });

    $(".js-car-filter-all").on("click", function () {
        $(".js-car-filter-item").show();
        $(".js-car-filter").prop('selectedIndex', 0);
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * trackday Form
    // *
    // *
    $(".js-trackday-checkbox-taxitoggler input").on("change", function(){
        if($(".js-trackday-checkbox-taxitoggler input:checked").length >= 1) {
            $(".js-trackday-checkbox-taxi").removeClass("disabled");
        }else {
            $(".js-trackday-checkbox-taxi").addClass("disabled");
            $(".js-trackday-checkbox-taxi input").prop("checked", false);
        }
    });

});









// * * * * * * * * * * * * * * * * * * * * * * * * *
// * teaserFan
// *
// *
$(window).on("load resize", function () {
    if ($(window).width() > 1000) {

        $(".js-teaserfan-button").click(function () {
            $(this).parents(".js-teaserfan").toggleClass("open");
        })

        $(".js-teaserfan").on("mouseenter", function () {
            $(".js-teaserfan").removeClass("active open");
            $(this).addClass("active");
        })

        $(".js-teaserfan:first-child").addClass("active");

    }
});

// * * * * * * * * * * * * * * * * * * * * * * * * *
// * sticky Header
// *
// *
$(window).on("load scroll", function () {
    var $header = $(".js-header");

    if ($(window).scrollTop() > 150) {
        $header.addClass("header--sticky");
    }

    if ($(window).scrollTop() < 20) {
        $header.removeClass("header--sticky");
    }

});
